const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M25.1973 124.579C25.3714 124.385 25.455 124.33 25.4546 124.327C32.4806 121.608 61.6522 112.159 84.8417 109.381C110.303 106.33 138.721 105.392 151.717 106.373C151.801 106.406 151.963 106.491 152.208 106.682C152.504 106.912 152.863 107.251 153.285 107.713C154.129 108.636 155.16 109.984 156.355 111.761C158.742 115.312 161.725 120.497 165.09 127.232C171.803 140.671 180.007 160.221 188 185.138V199H2.00027L2 186.099C5.8573 169.682 11.1958 154.384 15.8925 143.063C18.2465 137.389 20.4341 132.726 22.1869 129.429C23.0647 127.779 23.8244 126.487 24.434 125.588C24.7398 125.138 24.995 124.804 25.1973 124.579Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
