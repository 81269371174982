const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00778 199C4.217 185.108 8.62072 154.726 14.2945 125.264C17.1981 110.186 20.428 95.3824 23.5852 83.2001C26.6708 71.2942 29.6589 62.0083 32.1676 57.382C34.1648 56.9126 37.5834 56.8913 42.0753 57.2343C46.8171 57.5963 52.5979 58.3486 58.8382 59.2892C65.0758 60.2294 71.7535 61.355 78.2875 62.4611L79.1835 62.6127C85.4008 63.6654 91.4644 64.6921 96.8496 65.5114C106.934 67.0457 117.401 68.0051 127.046 68.8891C128.434 69.0164 129.806 69.1422 131.157 69.2679C141.704 70.2488 150.94 71.2221 157.33 72.8787C159.507 76.9112 162.163 83.861 164.982 92.7816C167.883 101.96 170.937 113.159 173.803 125.279C179.447 149.145 184.352 176.525 185.93 199H4.00778Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
