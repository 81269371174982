const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.2344 127.105C23.2892 127.037 23.3318 126.991 23.3621 126.96C31.258 125.895 61.8566 121.18 83.7524 115.468C95.321 112.45 107.747 107.678 118.819 103.112C122.909 101.425 126.806 99.7701 130.407 98.2405C136.37 95.7078 141.525 93.5187 145.412 92.096C145.51 92.1785 145.633 92.2934 145.779 92.4473C146.15 92.8389 146.615 93.4173 147.166 94.1861C148.265 95.7195 149.648 97.9253 151.242 100.701C154.427 106.247 158.414 114.001 162.592 123.044C170.937 141.104 180.015 164.236 185 185.118V199H5.00026L5 186.112C8.73737 169.683 12.844 155.004 16.3259 144.31C18.0715 138.948 19.6551 134.603 20.9518 131.547C21.6015 130.015 22.1695 128.831 22.6407 128.01C22.8772 127.598 23.0758 127.301 23.2344 127.105Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
