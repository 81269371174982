const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M173.556 118.037C178.458 133.727 185.881 157.486 185.999 199C174.771 198.99 153.395 198.833 127.915 198.645C121.403 198.597 114.622 198.547 107.674 198.497C73.098 198.249 34.3883 198 4.003 197.995C4.20205 164.729 14.2588 128.727 20.7282 107.204C33.3618 110.306 70.8785 116.048 93.2452 116.048C116.019 116.048 152.909 113.604 171.729 112.089C172.276 113.94 172.893 115.915 173.556 118.037Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
