const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.50585 199C3.72929 179.71 10.3219 153.447 16.9562 131.781C20.3254 120.777 23.695 110.992 26.2224 103.958C27.4859 100.441 28.5387 97.612 29.2752 95.6635C29.6435 94.6892 29.9327 93.935 30.1297 93.4248C30.227 93.1727 30.3018 92.9801 30.3525 92.8501C31.1427 92.1063 32.0728 91.6301 33.8204 91.3456C35.7397 91.0331 38.5519 90.9669 43.0786 91.0297C45.5156 91.0635 48.432 91.1341 51.9429 91.2191C61.3988 91.448 75.1674 91.7813 95.5 91.7813C121.783 91.7813 136.637 90.8913 145.358 90.3689C145.896 90.3366 146.411 90.3057 146.904 90.2766C151.173 90.024 153.676 89.9078 155.336 90.0848C156.144 90.1711 156.692 90.3224 157.114 90.5288C157.49 90.7126 157.811 90.9621 158.146 91.3268L158.163 91.3656C158.216 91.4899 158.296 91.6756 158.4 91.92C158.607 92.4088 158.912 93.1324 159.301 94.0695C160.078 95.9436 161.189 98.6713 162.522 102.081C165.188 108.902 168.744 118.45 172.299 129.355C179.31 150.862 186.283 177.541 186.495 199H3.50585Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
