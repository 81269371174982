const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50264 199C4.63799 173.255 9.96331 143.555 16.3843 117.833C22.723 92.4405 30.1071 71.0115 34.5609 61.1748C36.0183 61.5491 37.7787 62.0161 39.7891 62.5493C52.0377 65.7983 73.5636 71.508 92.3413 73.7204C113.74 76.2416 137.654 77.5201 156.696 76.3122C161.146 84.6497 168.24 102.704 174.279 124.933C180.372 147.361 185.367 173.956 185.497 199H4.50264Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
