const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M169.186 121.219C175.141 139.102 185.261 170.654 185.496 199C174.301 198.99 153.065 198.834 127.753 198.647C121.281 198.599 114.544 198.55 107.639 198.5C73.282 198.253 34.8022 198.005 4.50246 198C4.63021 172.09 9.70515 152.969 14.2956 135.673C15.5683 130.878 16.8037 126.223 17.8861 121.603C20.7586 119.664 25.2312 117.898 30.7679 116.346C36.4825 114.743 43.2407 113.391 50.354 112.301C64.582 110.121 80.1564 109 91.5 109C105.446 109 121.146 110.246 135.285 112.426C149.218 114.574 161.543 117.616 169.186 121.219Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
