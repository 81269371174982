const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.00043 199C7.02919 165.257 8.50916 137.019 9.98883 117.164C10.736 107.139 11.483 99.251 12.0429 93.8715C12.3229 91.1818 12.5562 89.1192 12.7193 87.7299C12.7809 87.2051 12.8325 86.7764 12.8728 86.4463C13.1347 86.4298 13.4561 86.4096 13.8334 86.3857C14.9899 86.3124 16.6713 86.2049 18.7715 86.0682C22.9718 85.7947 28.8476 85.4039 35.5495 84.9351C48.951 83.9974 65.6641 82.7468 78.8897 81.4956C92.0995 80.2458 110.171 78.6211 124.942 77.3086C132.327 76.6524 138.887 76.0743 143.6 75.6602C145.956 75.4532 147.851 75.2872 149.157 75.173L150.42 75.0625C150.452 75.1486 150.486 75.2423 150.524 75.3434C150.764 75.9957 151.117 76.958 151.566 78.1965C152.465 80.6736 153.749 84.2556 155.29 88.6731C158.372 97.5088 162.482 109.684 166.59 123.044C174.68 149.349 182.714 180.078 182.993 199H7.00043Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
