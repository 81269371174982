const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00255 199C4.21226 157.893 17.3074 123.677 27.7796 104.059C30.1958 103.512 33.6253 103.198 37.8053 103.018C42.2202 102.828 47.399 102.789 52.982 102.764C53.5525 102.761 54.1272 102.759 54.7056 102.756C65.4274 102.71 77.4433 102.659 88.1159 101.638C101.469 100.362 114.5 98.4473 125.284 96.8553L125.595 96.8095C135.865 95.2933 143.973 94.0964 148.41 94.0055C148.642 94.3415 148.88 94.6855 149.123 95.0373C160.942 112.127 185.677 147.892 185.997 199H4.00255Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
