const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.01706 199C4.17556 194.265 5.42957 188.522 7.42281 182.253C9.5543 175.549 12.5109 168.306 15.8049 161.152C22.2404 147.173 29.9366 133.59 35.201 125.125C45.4195 127.287 69.9064 131.333 91.8276 131.333C113.893 131.333 145.941 127.232 159.858 125.089C164.263 132.086 170.695 143.918 176.079 157.337C181.476 170.79 185.783 185.751 185.992 199H4.01706Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
