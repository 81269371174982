const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00131 199C4.08967 165.155 8.64559 129.064 14.5259 98.923C17.4946 83.7062 20.7987 70.0171 24.0312 58.9102C27.0895 48.402 30.0683 40.2595 32.6171 35.3202C48.3115 42.8115 74.8166 49.3862 96.6977 53.9862C99.2622 54.5254 101.725 55.0507 104.102 55.5576C123.748 59.7479 137.465 62.6734 153.437 61.6512C157.975 70.0521 165.991 90.5172 172.94 115.761C179.958 141.252 185.857 171.512 185.997 199H4.00131Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
