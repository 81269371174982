const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M151.813 124.199C151.822 124.222 151.828 124.238 151.831 124.246L151.847 124.318L151.886 124.405L184.963 199H4.0524L7.4918 131.685C7.49183 131.685 7.49185 131.685 7.49187 131.685C7.51836 131.614 7.5651 131.5 7.63902 131.356C7.78697 131.069 8.04256 130.664 8.46149 130.24C9.28357 129.408 10.806 128.428 13.583 128.197C18.5434 127.783 110.539 122.799 141.548 121.299C146.591 121.055 149.185 121.967 150.484 122.803C151.131 123.22 151.474 123.629 151.65 123.898C151.739 124.035 151.789 124.14 151.813 124.199Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
