const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00085 199C4.11423 132.211 15.5526 77.3187 20.6756 56.4343C28.6088 60.1497 41.3491 64.1587 54.8278 67.6507C68.9791 71.3169 84.0311 74.4353 95.3581 76.0591C119.524 79.5233 151.525 82.0226 170.264 80.6448C174.864 96.8445 185.865 144.495 185.999 199H4.00085Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
