const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50172 199C4.67798 147.938 18.3244 114.181 27.132 95.2568C27.2448 95.2189 27.4055 95.1765 27.6271 95.1379C28.0381 95.0663 28.5767 95.021 29.2458 95.0058C30.5829 94.9753 32.3456 95.0667 34.4625 95.255C38.6919 95.6313 44.2229 96.3841 50.3667 97.2598C51.7201 97.4527 53.103 97.6515 54.5076 97.8534C65.8211 99.4801 78.5402 101.309 88.5904 101.867C90.8714 101.994 93.1027 102.12 95.2906 102.245C114.855 103.356 130.945 104.27 148.012 103.165C162.979 117.049 185.202 149.389 185.497 199H4.50172Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
