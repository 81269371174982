const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='170' height='200' viewBox='0 0 170 201' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='170' height='201'>
      <rect width='170' height='201' fill='#000000' />
    </mask>
    <g mask='url(#mask0)'>
      <path
        d='M167 172.927V199H3V172.927L16.02 160.463L16.0858 160.4L16.1093 160.368C16.132 160.353 16.2013 160.31 16.3486 160.256C16.5995 160.165 17.0064 160.07 17.6125 160.025C18.8243 159.937 20.7385 160.058 23.5854 160.715C30.9653 162.418 42.0647 164.5 84.5 164.5C126.931 164.5 139.033 162.422 146.415 160.719C149.261 160.062 151.176 159.94 152.388 160.029C152.994 160.073 153.401 160.168 153.651 160.259C153.799 160.313 153.868 160.356 153.891 160.371L153.914 160.404L153.98 160.467L167 172.927Z'
        stroke={stroke}
        strokeWidth='4'
      />
      <path d='M71 180V199M99 180.5V199' stroke={stroke} strokeOpacity='0.3' strokeWidth='4' strokeDasharray='4 3' />
      <rect x='71' y='199' width='28' height='20' stroke={stroke} strokeWidth='4' />
      <path
        d='M15.75 188C15.75 186.903 15.993 185.866 16.4273 184.936L15.7478 184.618C16.5423 182.917 17.9174 181.542 19.6182 180.748L19.9356 181.427C20.8655 180.993 21.9033 180.75 23 180.75H25.4603V180H29.3968V180.75H34.3175V180H38.254V180.75H43.1746V180H47.1111V180.75H52.0317V180H55.9683V180.75H60.8889V180H64.8254V180.75H69.746V180H73.6825V180.75H78.6032V180H82.5397V180.75H87.4603V180H91.3968V180.75H96.3175V180H100.254V180.75H105.175V180H109.111V180.75H114.032V180H117.968V180.75H122.889V180H126.825V180.75H131.746V180H135.683V180.75H140.603V180H144.54V180.75H147C148.097 180.75 149.134 180.993 150.064 181.427L150.382 180.748C152.083 181.542 153.458 182.917 154.252 184.618L153.573 184.936C154.007 185.866 154.25 186.903 154.25 188V191.333H155V196.667H154.25V199.25H152.569V200H148.681V199.25H143.819V200H139.931V199.25H135.069V200H131.181V199.25H126.319V200H122.431V199.25H117.569V200H113.681V199.25H108.819V200H104.931V199.25H100.069V200H96.1806V199.25H91.3194V200H87.4306V199.25H82.5694V200H78.6806V199.25H73.8194V200H69.9306V199.25H65.0694V200H61.1806V199.25H56.3194V200H52.4306V199.25H47.5694V200H43.6806V199.25H38.8194V200H34.9306V199.25H30.0694V200H26.1806V199.25H21.3194V200H17.4306V199.25H15.75V196.667H15V191.333H15.75V188Z'
        stroke={stroke}
        strokeOpacity='0.3'
        strokeWidth='1.5'
        strokeDasharray='5 4'
      />
    </g>
  </svg>
)
export default KeySvg
