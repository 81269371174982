const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.00328 199C3.16684 174.037 9.41734 150.038 15.6833 132.182C18.8564 123.14 22.0292 115.685 24.4074 110.493C25.5965 107.898 26.5866 105.868 27.2784 104.49C27.6243 103.801 27.8956 103.274 28.0799 102.921C28.172 102.744 28.2424 102.611 28.2894 102.523L28.2904 102.521C28.3387 102.477 28.4151 102.412 28.5252 102.328C28.7937 102.122 29.2625 101.804 30.0144 101.409C31.5201 100.618 34.1488 99.5288 38.5507 98.4285C47.3594 96.2266 63.1945 94 91.1781 94C119.159 94 135.639 96.226 145.095 98.4322C149.821 99.5348 152.782 100.63 154.541 101.433C155.42 101.835 155.998 102.162 156.345 102.38C156.474 102.461 156.571 102.527 156.64 102.576C156.696 102.685 156.776 102.84 156.877 103.039C157.1 103.475 157.427 104.121 157.843 104.957C158.676 106.63 159.867 109.066 161.297 112.114C164.156 118.209 167.969 126.747 171.781 136.514C179.289 155.752 186.742 179.629 186.993 199H3.00328Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
