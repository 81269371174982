const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.6532 124.266C27.7446 124.162 27.8106 124.097 27.8524 124.058C30.9339 123.675 37.1032 123.49 44.9019 123.256C46.7162 123.202 48.6187 123.145 50.591 123.082C61.1957 122.744 73.7262 122.241 85.102 121.074C107.341 118.793 134.254 111.274 149.647 106.973C150.801 106.65 151.89 106.346 152.907 106.064C152.986 106.12 153.096 106.208 153.239 106.343C153.551 106.638 153.935 107.074 154.388 107.662C155.291 108.836 156.402 110.531 157.678 112.694C160.226 117.015 163.382 123.125 166.779 130.488C173.559 145.182 181.27 164.807 187 185.059V199H3.00026L3 186.061C7.48078 169.824 13.1972 154.462 18.1064 143.03C20.5678 137.298 22.8214 132.566 24.609 129.213C25.5041 127.534 26.2736 126.217 26.8871 125.299C27.1949 124.839 27.4509 124.497 27.6532 124.266Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
