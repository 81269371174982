const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50455 199C4.6689 180.594 9.2691 146.17 15.1851 113.697C18.1952 97.1738 21.541 81.1829 24.8061 68.0998C28.0097 55.2631 31.1094 45.3384 33.702 40.4233C34.566 40.1376 35.7463 39.9911 37.2479 40.0004C38.9295 40.0108 40.9365 40.2151 43.2136 40.578C47.7686 41.3041 53.3076 42.6489 59.3139 44.2594C63.0593 45.2636 66.9875 46.3718 70.9601 47.4925C79.6778 49.9519 88.6095 52.4716 96.2944 54.086C103.527 55.6053 110.723 56.9381 117.55 58.2025C122.096 59.0442 126.478 59.8557 130.597 60.6717C140.821 62.697 149.457 64.755 155.331 67.3922C157.482 70.6104 160.412 77.3211 163.619 86.4385C166.889 95.735 170.412 107.432 173.656 120.241C180.058 145.523 185.352 175.061 185.497 199H4.50455Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
