const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M186.023 199H5.54523L7.99902 143.544L8 143.522V143.506L8.00041 143.492C8.00103 143.475 8.00247 143.445 8.00574 143.403C8.0123 143.319 8.02612 143.19 8.0551 143.025C8.11332 142.695 8.23088 142.237 8.46624 141.741C8.92596 140.77 9.85414 139.603 11.8162 138.949C11.9738 138.896 12.5928 138.75 13.7172 138.511C14.8068 138.279 16.3045 137.973 18.155 137.602C21.8552 136.862 26.9541 135.868 32.9948 134.703C45.0756 132.374 60.9149 129.365 76.8466 126.346C82.0013 125.369 87.1672 124.391 92.2193 123.434C118.373 118.483 141.48 114.108 144.225 113.474C147.229 112.781 149.362 113.365 150.726 114.075C151.417 114.434 151.924 114.832 152.253 115.135C152.418 115.286 152.537 115.412 152.611 115.496C152.618 115.503 152.624 115.51 152.63 115.516L186.023 199Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
