const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M185.417 199H5.03635L6.99936 144.036L7 144.018V144.011C7.00009 144.009 7.00022 144.006 7.00043 144.003C7.00138 143.987 7.00379 143.955 7.00964 143.909C7.02135 143.817 7.04667 143.669 7.10097 143.482C7.20908 143.11 7.43268 142.578 7.89896 142.008C8.81633 140.887 10.7957 139.473 15.1104 138.994C15.322 138.97 15.6008 138.939 15.9435 138.901C27.6552 137.598 114.032 127.987 144.066 125.998C147.12 125.796 149.157 126.609 150.414 127.426C151.048 127.838 151.495 128.259 151.778 128.567C151.92 128.722 152.019 128.848 152.081 128.93C152.101 128.957 152.117 128.979 152.128 128.995L185.417 199Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
