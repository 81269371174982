const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50244 199C4.68427 161.736 14.9949 138.842 19.7096 128.374C20.1807 127.328 20.5959 126.406 20.9396 125.606C21.7378 123.748 24.0563 121.821 27.9872 119.998C31.8644 118.2 37.1059 116.595 43.4209 115.256C56.0437 112.581 72.8097 111 91.1875 111C109.578 111 127.943 112.266 142.164 114.787C149.279 116.049 155.315 117.618 159.786 119.48C162.021 120.41 163.833 121.401 165.185 122.439C166.542 123.481 167.384 124.53 167.772 125.563C168.366 127.142 169.197 129.073 170.179 131.355C175.516 143.755 185.3 166.488 185.497 199H4.50244Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
