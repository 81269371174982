const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M29.5392 87.4095C29.6485 87.2657 29.7423 87.1543 29.8204 87.0705C46.9457 90.5508 74.0191 94 96.7548 94C120.22 94 139.232 92.0212 156.245 89.0448C156.341 89.1263 156.468 89.2513 156.624 89.4317C156.969 89.8282 157.394 90.418 157.894 91.2065C158.892 92.7787 160.125 95.0449 161.523 97.9008C164.318 103.607 167.736 111.591 171.206 120.911C178.14 139.542 185.25 163.434 188 185.063V199H2.00027L2 186.058C5.00132 160.409 11.2457 135.872 17.1793 117.558C20.1483 108.395 23.0353 100.802 25.3927 95.4167C26.5725 92.7216 27.6124 90.5963 28.457 89.1091C28.8804 88.3635 29.2432 87.7991 29.5392 87.4095Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
