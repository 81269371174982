const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.02295 199C4.23821 194.164 5.95213 187.623 8.5035 180.383C11.2245 172.662 14.8578 164.253 18.499 156.464C22.139 148.677 25.7799 141.524 28.5114 136.317C29.877 133.714 31.0147 131.598 31.8107 130.133C32.2087 129.401 32.5213 128.831 32.7341 128.446C32.7682 128.384 32.7998 128.326 32.8288 128.274C33.0947 128.228 33.4701 128.164 33.9453 128.083C34.9717 127.909 36.4638 127.661 38.3244 127.363C42.0459 126.767 47.2403 125.972 53.131 125.176C64.926 123.585 79.463 122 90.5586 122C101.661 122 118.105 123.586 131.811 125.179C138.656 125.974 144.805 126.77 149.242 127.367C151.46 127.665 153.251 127.913 154.486 128.087C155.104 128.174 155.583 128.243 155.907 128.289L155.961 128.297C155.989 128.34 156.02 128.386 156.053 128.436C156.274 128.77 156.599 129.266 157.014 129.906C157.843 131.187 159.028 133.05 160.451 135.371C163.297 140.016 167.091 146.492 170.884 153.824C174.678 161.158 178.464 169.332 181.298 177.373C183.998 185.03 185.814 192.509 185.987 199H4.02295Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
