const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.50375 199C3.62991 182.042 6.92249 158.68 11.5281 137.021C16.1708 115.186 22.1137 95.2441 27.4123 85.3775C29.4665 84.8994 32.5854 84.902 36.5721 85.2369C40.7871 85.5909 45.825 86.3 51.3362 87.0941C51.746 87.1531 52.1585 87.2126 52.5734 87.2725C63.2809 88.8176 75.5917 90.5941 86.7068 90.5941H86.7331C112.004 90.5941 129.561 90.5941 147.541 89.3557C163.85 106.999 186.227 144.352 186.498 199L3.50375 199Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
