const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M29.8465 113.006C29.8649 112.991 29.8812 112.979 29.8954 112.968C31.7118 112.761 35.0296 112.838 39.4413 113.067C42.2015 113.21 45.3425 113.41 48.7482 113.626C50.9419 113.766 53.2455 113.912 55.6277 114.055C67.7541 114.782 81.8559 115.412 93.3247 114.497C118.29 112.505 139.281 109.028 156.281 106.039C156.359 106.092 156.478 106.186 156.64 106.343C156.948 106.641 157.327 107.083 157.772 107.681C158.66 108.874 159.747 110.595 160.987 112.789C163.463 117.171 166.503 123.356 169.721 130.773C176.145 145.577 183.253 165.224 188 185.118V199H2.00027L2 186.125C6.99609 166.47 13.2333 148.435 18.6579 135.127C21.3763 128.458 23.8852 122.99 25.9256 119.106C26.9471 117.161 27.8414 115.632 28.5776 114.554C28.9463 114.014 29.2629 113.605 29.5246 113.319C29.6549 113.177 29.7622 113.075 29.8465 113.006Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
