const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50231 199C4.7497 145.392 24.8002 95.4398 32.9313 75.1825C33.0774 74.8185 33.2197 74.464 33.358 74.1193C33.5933 74.1584 33.8785 74.2054 34.2105 74.2597C35.2744 74.4338 36.8185 74.6823 38.7417 74.9804C42.5876 75.5766 47.9504 76.3715 54.0185 77.1665C66.141 78.7547 81.1282 80.35 92.4583 80.35C114.639 80.35 145.208 77.8249 159.275 75.3808C163.685 83.7264 170.137 101.992 175.543 124.431C181.011 147.126 185.384 173.987 185.498 199H4.50231Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
