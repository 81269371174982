const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00665 199C4.23952 181.307 10.5548 157.523 16.9159 137.939C20.1505 127.979 23.3857 119.139 25.8122 112.789C27.0253 109.614 28.036 107.062 28.7431 105.305C29.0966 104.427 29.3742 103.747 29.5632 103.287C29.6577 103.057 29.7301 102.882 29.7787 102.765L29.8268 102.65C31.0368 100.594 33.0541 99.1798 36.1607 98.1863C39.3178 97.1766 43.4999 96.6339 48.8806 96.2694C52.6691 96.0128 57.0556 95.8458 62.0536 95.6555C71.1131 95.3106 82.1814 94.8891 95.3379 93.7189C103.213 93.0185 111.292 91.703 119.15 90.4238C122.525 89.8743 125.858 89.3316 129.117 88.8471C139.87 87.2485 149.814 86.2823 158.046 87.6586C159.137 89.0149 160.389 91.2801 161.748 94.3409C163.19 97.5882 164.717 101.649 166.278 106.295C169.398 115.584 172.632 127.157 175.557 139.115C181.254 162.403 185.744 186.999 185.989 199H4.00665Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
