const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M171.431 117.565C176.587 134.446 185.337 163.098 185.498 199C174.304 198.99 153.066 198.834 127.753 198.647C121.281 198.599 114.544 198.55 107.639 198.5C73.2819 198.253 34.8021 198.005 4.50232 198C4.64241 167.878 11.0966 146.887 17.0985 127.368C17.6391 125.61 18.1759 123.864 18.7042 122.124C24.8858 123.026 37.0019 123.241 50.2821 123.121C64.2628 122.995 79.6498 122.494 91.0434 121.999C102.604 121.496 117.906 119.363 132.585 117.177C134.958 116.823 137.315 116.468 139.637 116.119C151.297 114.363 162.089 112.739 169.77 112.065C170.26 113.728 170.82 115.564 171.431 117.565Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
