const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.50275 199C4.63226 175.467 9.31302 155.024 14.4553 139.792C19.524 124.777 25.0192 114.888 27.0019 112.104C27.6551 111.893 28.6212 111.736 29.9115 111.646C31.3612 111.545 33.1394 111.535 35.1926 111.594C39.2994 111.712 44.4297 112.105 50.0934 112.581C51.4149 112.692 52.7651 112.807 54.1372 112.925C64.4981 113.81 76.1137 114.803 86.1042 114.803C97.4126 114.803 109.524 113.848 120.529 112.898C121.835 112.785 123.126 112.672 124.397 112.561C133.663 111.751 141.909 111.031 147.987 111.001C163.01 124.793 185.201 149.654 185.497 199H4.50275Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
