const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.0033 199C4.14469 177.475 8.81572 153.235 14.5884 132.504C20.3598 111.776 27.1958 94.6857 31.6264 87.3779C34.2152 86.8784 38.0298 86.9005 42.7348 87.2897C47.6628 87.6973 53.4603 88.4967 59.6525 89.4469C62.8671 89.9402 66.1888 90.4743 69.5462 91.0142C79.0901 92.5489 88.9225 94.13 97.4037 94.9507C108.47 96.0215 118.617 96.5203 128.424 96.9379C129.836 96.9981 131.24 97.0565 132.639 97.1147C140.801 97.4544 148.777 97.7863 156.936 98.4004C161.466 105.334 168.642 119.984 174.734 138.156C180.847 156.391 185.841 178.089 185.996 199H4.0033Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
