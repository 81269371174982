const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M185.52 199H5.04442L7.49609 142.612C7.4984 142.6 7.50129 142.587 7.50483 142.572C7.52041 142.504 7.54844 142.397 7.59495 142.26C7.68825 141.986 7.85356 141.603 8.13654 141.191C8.68911 140.388 9.72785 139.418 11.7169 138.976C15.4541 138.146 112.682 118.983 143.177 113.484L143 112.5L143.177 113.484C146.973 112.8 149.215 113.528 150.473 114.309C151.112 114.705 151.528 115.134 151.779 115.45C151.905 115.608 151.989 115.738 152.038 115.822C152.062 115.862 152.077 115.891 152.086 115.907C152.086 115.907 152.086 115.908 152.087 115.909L185.52 199Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
