const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M49.1481 177.776H48.6449V199H42.7902V167.073H51.0234L58.2962 188.297H58.7993V167.073H64.6541V199H56.4208L49.1481 177.776ZM81.1485 199.549C76.8794 199.549 73.7233 198.1 71.6803 195.204C69.6677 192.307 68.6614 188.251 68.6614 183.037C68.6614 177.822 69.6677 173.767 71.6803 170.87C73.7233 167.973 76.8794 166.524 81.1485 166.524C85.4176 166.524 88.5585 167.973 90.5711 170.87C92.6141 173.767 93.6357 177.822 93.6357 183.037C93.6357 188.251 92.6141 192.307 90.5711 195.204C88.5585 198.1 85.4176 199.549 81.1485 199.549ZM81.1485 194.014C83.1001 194.014 84.4418 193.298 85.1737 191.864C85.936 190.401 86.3172 188.373 86.3172 185.781V180.246C86.3172 177.685 85.936 175.688 85.1737 174.254C84.4418 172.791 83.1001 172.059 81.1485 172.059C79.1969 172.059 77.84 172.791 77.0776 174.254C76.3458 175.688 75.9799 177.685 75.9799 180.246V185.827C75.9799 188.388 76.3458 190.401 77.0776 191.864C77.84 193.298 79.1969 194.014 81.1485 194.014ZM104.001 177.776H103.498V199H97.643V167.073H105.876L113.149 188.297H113.652V167.073H119.507V199H111.274L104.001 177.776ZM125.938 199V167.073H146.339V172.699H132.799V180.018H145.881V185.644H132.799V193.374H146.339V199H125.938Z'
      fill={stroke}
      fillOpacity='0.3'
    />
  </svg>
)
export default KeySvg
