const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M153.078 106.3L153.078 106.3L153.08 106.308L153.085 106.322L184.604 199H4.53242L7.49783 106.084C7.50105 106.064 7.50647 106.034 7.51491 105.995C7.53555 105.898 7.57396 105.749 7.642 105.563C7.77805 105.192 8.03048 104.683 8.49329 104.169C9.39271 103.17 11.2249 102 15 102L145 102C148.503 102 150.513 103.158 151.641 104.226C152.215 104.77 152.582 105.31 152.802 105.705C152.911 105.902 152.984 106.063 153.027 106.167C153.049 106.22 153.063 106.258 153.071 106.28C153.074 106.289 153.077 106.296 153.078 106.299C153.078 106.299 153.078 106.299 153.078 106.3Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
