const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00351 199C4.14753 178.348 8.70816 152.141 14.5707 128.554C20.3987 105.107 27.4798 84.3826 32.7098 74.3609C37.9075 75.4562 46.6876 75.3993 56.7241 74.7996C67.3263 74.166 79.4921 72.9133 90.669 71.6725C106.817 69.8798 127.605 65.5149 143.193 62.242C149.267 60.9667 154.551 59.8571 158.464 59.1302C161.733 67.2971 168.457 88.8772 174.446 114.96C180.549 141.538 185.865 172.694 185.997 199H4.00351Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
