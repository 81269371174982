const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M179.33 147.063C182.807 165.237 185.38 184.679 185.496 199C174.301 198.99 153.065 198.834 127.753 198.647C121.281 198.599 114.544 198.55 107.639 198.5C73.2818 198.253 34.8017 198.005 4.50189 198C4.62643 165.19 10.8825 147.013 18.185 126.555C24.3351 126.969 36.7556 126.566 50.3707 125.778C64.6128 124.953 80.2541 123.702 91.6058 122.494C114.777 120.029 152.354 108.403 167.927 103.244C171.565 112.361 175.882 129.034 179.33 147.063Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
