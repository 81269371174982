const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.00149 199C5.18458 137.579 22.1892 86.9422 32.7392 67.2354C34.7147 66.2152 37.7591 65.8576 41.7247 66.05C45.8163 66.2485 50.7103 67.0199 56.0669 68.0692C60.1189 68.8628 64.4461 69.8188 68.867 70.7955C76.054 72.3832 83.4884 74.0256 90.3896 75.1142C101.879 76.9264 116.534 77.8818 129.073 78.6994L129.139 78.7037C135.429 79.1137 141.179 79.489 145.756 79.9201C148.044 80.1356 150.024 80.3638 151.623 80.6151C153.01 80.833 154.058 81.0608 154.76 81.2934C154.969 81.6741 155.185 82.0657 155.408 82.4685C164.729 99.3585 184.789 135.708 184.998 199H5.00149Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
