const KeySvg = ({ stroke = '#00ff00' }) => (
  <svg width='190' height='200' viewBox='0 0 190 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.50373 199C3.69024 173.891 10.8427 145.452 18.0238 123.138C21.6587 111.843 25.2938 102.138 28.0198 95.2584C29.3827 91.8186 30.5181 89.0857 31.3121 87.214C31.7092 86.2781 32.0209 85.5576 32.233 85.0717C32.3391 84.8288 32.4202 84.6445 32.4747 84.5214L32.4802 84.5091C32.5282 84.4665 32.5969 84.4087 32.6898 84.337C32.9566 84.1314 33.4226 83.8127 34.1702 83.4176C35.6671 82.6266 38.2809 81.5357 42.6584 80.4341C51.4185 78.2296 67.1674 76 95 76C122.83 76 139.22 78.229 148.624 80.4378C153.324 81.5418 156.268 82.6382 158.018 83.4422C158.892 83.8439 159.466 84.1719 159.811 84.3901C159.906 84.45 159.983 84.5016 160.045 84.5441C160.094 84.6858 160.16 84.8788 160.243 85.1212C160.437 85.6896 160.723 86.5292 161.086 87.6122C161.812 89.7782 162.85 92.9178 164.096 96.8092C166.588 104.593 169.91 115.381 173.231 127.403C179.773 151.078 186.277 179.408 186.494 199H3.50373Z'
      stroke={stroke}
      strokeWidth='4'
    />
  </svg>
)
export default KeySvg
